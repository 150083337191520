<template>
  <el-dialog title="门店二维码" top="15vh" :close-on-click-modal="false" :visible.sync="showDialog" width="40%" >
 <div class="imgCode" v-loading="diaoloading">
    
   <div class="code">
    <!-- <div id="qrcode" class="wx_code" ref="qrcode"></div> -->
      <el-image  class="wx_code" :src="codeUrl"></el-image>
    <div class="code_text">
     <div>打开微信扫描二维码</div>
     <div>授权入住智慧酒店</div>
    </div>
   </div>
   <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="createPicture">下载二维码</el-button>
   </div>
 </div>
  </el-dialog>
</template>
<script>
import QRCode from "qrcodejs2";
import { decrypted } from "@/api/common";
import html2canvas from "html2canvas";
import { getHetelQrCode } from "@/api";


export default {
 data() {
  return {
   text: null,
   showDialog: false,
   codeUrl:null,
   diaoloading:false,
    hotelData:{}
  };
 },
 methods: {
  openSet(row) {
   console.log(row);
   this.hotelData=row
   this.showDialog = true;
   this.getHetelQrCode(row.id)
  //  let str = `${row.pmsHotelId}-${row.brandName}-${row.brandId}`;
  //  let text = decrypted(str, "defaultKey");
  //  console.log(text, "-------");
  //  this.$nextTick(() => {
  //   this.creatQrCode(text);
  //  });
  },
  getHetelQrCode(hotelId){
    this.diaoloading=true
    this.codeUrl=null
    getHetelQrCode({hotelId}).then(res=>{
      console.log(res);
    this.diaoloading=false

      if(res.data.code=='000000'){
        this.codeUrl=`data:image/png;base64,${res.data.data}`
      }
    }).catch(err=>{
this.diaoloading=false
    })
  },
  // creatQrCode(text) {
  //    document.getElementById("qrcode").innerHTML = "";
  //  new QRCode(this.$refs.qrcode, {
  //   width: 250,
  //   height: 250,
  //   text: text, // 二维码地址
  //   colorDark: "#000", // 二维码颜色
  //   colorLight: "#fff", // 二维码背景色
  //  });
  // },
  createPicture() {
  //  html2canvas(this.$refs.qrcode)
    // .then((canvas) => {
        // var imgData = canvas.toDataURL("image/jpeg");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download=this.hotelData.name?`${this.hotelData.name} 二维码`:"门店二维码"
        a.href = this.codeUrl; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    // })
    // .catch((error) => {});
  },
  
 },
};
</script>

<style scoped lang="scss">

.box-card {
 width: 100%;
 height: 100%;
}
.code {
 width: 250px;
 background-color: #fff;
 margin: 0 auto;
 .wx_code {
  width: 250px;
  height: 250px;
 }
 .code_text {
  // width: 100%;
  padding-top: 20px;
  line-height: 22px;
  text-align: center;
 }
}
</style>
