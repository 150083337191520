<template>
 <div class="my-box">
  <el-form :model="addform" ref="addform" :rules="myrules" label-width="120px">
   <el-form-item label="集团/品牌" prop="brandId" v-if="!user.brandId">
    <el-select v-model="addform.brandId" clearable filterable placeholder="请选择">
     <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
    </el-select>
   </el-form-item>
   <el-form-item label="门店" prop="hotelName">
    <el-input v-model.trim="addform.hotelName" placeholder="请输入内容" maxlength="15"></el-input>
   </el-form-item>
  <el-form-item label="门店编号" prop="hotelCode">
     <el-input v-model.trim="addform.hotelCode" placeholder="请输入内容"></el-input>
    </el-form-item>
   <el-form-item label="PMS门店编号">
     <el-input v-model.trim="addform.pmsHotelId" placeholder="请输入内容"></el-input>
    </el-form-item>
     <el-form-item label="主机类型" prop="mainframeType">
     <el-select placeholder="请选择" clearable filterable v-model="addform.mainframeType" style="width:200px">
      <el-option v-for="(item, index) in hostType" :key="index" :label="item" :value="item"></el-option>
     </el-select>
    </el-form-item>
   <el-form-item label="房型" prop="roomType">
    <el-checkbox-group v-model="addform.roomType">
     <el-checkbox v-for="(item,index) in roomTypeList" :key="index" :label="item.value">
      <el-input style="width:120px" v-if="item.type=='input'" v-model.trim="item.lable" placeholder="请输入内容" size="mini" maxlength="15" @blur="addCustomRoomType(item.lable,index)"></el-input>
      <span v-else>{{item.lable}}</span>
     </el-checkbox>
    </el-checkbox-group>
   </el-form-item>
   <el-form-item label="建筑数" prop="buildCount">
    <el-input-number v-model="addform.buildCount" :precision="0" :min="1" controls-position="right"></el-input-number>
   </el-form-item>
  
  </el-form>
  <div class="dialog-footer ">
   <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">下一步</el-button>
  </div>
 </div>
</template>
<script>
export default {
 props: ["brandSelectData"],
 data() {
  let roomTypeVerify = (rule, value, callback) => {
   console.log(value, "---ads");
   if (!value || value.length == 0) {
    return callback(new Error("房型不能为空"));
   } else {
    let inputArr = this.roomTypeList.filter((item) => item.type == "input");
    console.log(inputArr);
    let flag = inputArr.some(
     (item) => value.includes(item.value) && !item.lable
    );
    if (flag) {
     return callback(new Error("请输入房型"));
    } else {
     callback();
    }
   }
  };
  return {
   loading: false,
   and: "roomtypeAnd",
   addform: {
    hotelName: null,
    brandId: null,
    buildCount: 1,
    hotelCode:null,
    pmsHotelId:null,
    roomType: [],
   },
   roomTypeList: null,
   myrules: {
      mainframeType: [{ required: true, message: "请选择", trigger: "change" }],
    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    roomType: [
     //  { required: true, message: "请选择", trigger: ["blur"] },
     { required: true, validator: roomTypeVerify, trigger: ["blur", "change"] },
    ],
    hotelName: [{ required: true, message: "请输入", trigger: "blur" }],
    buildCount: [{ required: true, message: "请输入", trigger: "blur" }],
   },
  };
 },
 computed: {
  user() {
   return JSON.parse(sessionStorage.getItem("user"));
  },
   hostType() {
   return this.$store.state.hostType;
  },
 },
 methods: {
  getRoomTypeList() {
   this.roomTypeList = JSON.parse(
    JSON.stringify(this.$store.state.roomTypeList)
   );
   let length = this.roomTypeList.length.toString();
   this.roomTypeList.push({ lable: null, type: "input", value: length });
  },
  addCustomRoomType(val, index) {
   if (val && index == this.roomTypeList.length - 1) {
    let length = this.roomTypeList.length.toString();
    this.roomTypeList.push({ lable: null, type: "input", value: length });
   }
  },
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     console.log(this.addform);
     let data = this.getData();
     console.log(data);
     this.loading = true;
     setTimeout(() => {
      this.loading = false;
      this.$emit("commitData1", data);
     }, 1000);
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  getData() {
   let data = new Object(),
    roomTypeList = new Array();
   this.addform.roomType.join(",");
   let roomTypes = new Array();
   this.roomTypeList.forEach((item) => {
    if (this.addform.roomType.includes(item.value)) {
     roomTypes.push({ roomTypeName: `${this.addform.hotelName}_${item.lable}` });
     roomTypeList.push({ typeName: `${this.addform.hotelName}_${item.lable}` });
    }
   });
   
   data.equipmentList = roomTypes; //用来后面的设备管理

   data.roomTypeList = roomTypeList; //记录房型
   ['hotelName','mainframeType','buildCount','pmsHotelId','hotelCode'].forEach(key=>data[key]=this.addform[key])
   if (this.addform.brandId) {
    data.brandId = this.addform.brandId;
    data.brandName = this.brandSelectData.filter(
     (item) => item.id == data.brandId
    )[0].brandName;
   }
   return data;
  },
  init() {
   this.getRoomTypeList();
   this.addform = {
     hotelName: null,
    brandId: null,
    buildCount: 1,
    hotelCode:null,
    pmsHotelId:null,
    roomType: [],
   };
   setTimeout(() => {
    this.$refs.addform.clearValidate();
   }, 10);
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 padding: 30px 30px 0;
}
</style>


