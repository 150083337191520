<template>
 <div class="my-box">
  <el-form :model="addform" ref="addform" :rules="myrules" label-width="100px">
   <el-card v-for="(item,index) in addform.list" :key="index" style="margin-bottom:20px;padding:20px 0">
    <el-row>
     <el-col :span="10">
      <el-form-item label="楼层" :prop="'list.'+index+'.beg'" :rules="myrules.beg">
       <el-input-number v-model="addform.list[index].beg" style="width:100%" controls-position="right"></el-input-number>
      </el-form-item>
     </el-col>
     <el-col :span="10">

      <el-form-item label="至" :prop="'list.'+index+'.end'" :rules="myrules.end">
       <el-input-number v-model="addform.list[index].end" style="width:100%" controls-position="right"></el-input-number>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label=" " label-width="20px">
       <el-button size="mini" type="primary" icon="el-icon-plus" @click="addFloor" circle></el-button>
       <el-button size="mini" type="primary" icon="el-icon-minus" @click="minusFloor(index)" v-if="index>0" circle></el-button>
      </el-form-item>
     </el-col>
     <el-col :span="10">
      <el-form-item label="楼层类型" :prop="'list.'+index+'.floorType'" :rules="myrules.floorType">
       <el-select v-model="addform.list[index].floorType" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in floorTypes" :key="index" :label="item.lable" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="10">
      <el-form-item label="梯控顺序" :prop="'list.'+index+'.elevatorSorts'" :rules="myrules.elevatorSorts">
       <el-input v-model="addform.list[index].elevatorSorts" placeholder="请输入梯控顺序"></el-input>
      </el-form-item>
     </el-col>

    </el-row>

   </el-card>
  </el-form>
  <div class="dialog-footer ">
   <el-button @click="topPage">上一步</el-button>
   <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">下一步</el-button>
  </div>
 </div>
</template>
<script>
export default {
 props: ["brandSelectData"],
 data() {
  let startFloorVerify = (rule, value, callback) => {
   let index = rule.fullField.split(".")[1];
   if (!value) {
    return callback(new Error("请输入楼层"));
   } else {
    if (value > this.addform.list[index].end) {
     return callback(new Error("开始楼层不得大于结束楼层"));
    } else if (index > 0 && value < this.addform.list[index - 1].end) {
     return callback(new Error("开始楼层小于上一级的结束楼层"));
    } else {
     //  this.$refs.addform.validateField('list.'+index+'.end')
     //  if(index>0){
     //     this.$refs.addform.validateField('list.'+index-1+'.end')
     //  }
     callback();
    }
   }
  };
  let endFloorVerify = (rule, value, callback) => {
   let index = rule.fullField.split(".")[1];
   if (!value) {
    return callback(new Error("请输入楼层"));
   } else {
    if (value < this.addform.list[index].beg) {
     return callback(new Error("结束楼层不得小于开始楼层"));
    } else {
     //  this.$refs.addform.validateField('list.'+index+'.beg')
     //  this.$refs.addform.validateField('list.'+index+1+'.beg')
     callback();
    }
   }
  };
  return {
   floorTypes: [
    {
     lable: "客房区域",
     value: 0,
    },
    {
     lable: "公共区域",
     value: 1,
    },
   ],
   loading: false,
   and: "roomtypeAnd",
   addform: {
    list: [{ beg: null, end: null, floorType: null, elevatorSorts: null }],
   },
   damoList: { beg: null, end: null, floorType: null, elevatorSorts: null },

   myrules: {
    floorType: [
     { required: true, message: "请选择", trigger: ["blur", "change"] },
    ],
    // elevatorSorts: [
    //  { required: true, message: "请按要求输入梯控序号",  trigger: ["blur", "change"], },
    // ],
    beg: [
     { required: true, message: "请输入楼层", trigger: ["blur", "change"] },
     {
      required: true,
      validator: startFloorVerify,
      trigger: ["blur", "change"],
     },
    ],
    end: [
     { required: true, message: "请输入楼层", trigger: ["blur", "change"] },
     { required: true, validator: endFloorVerify, trigger: ["blur", "change"] },
    ],
   },
  };
 },

 methods: {
  topPage() {
   this.$emit("topPage");
  },
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     setTimeout(() => {
      this.loading = false;
      this.$emit("commitData2", this.addform.list);
     }, 1000);
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },

  minusFloor(i) {
   this.addform.list.splice(i, 1);
  },
  addFloor() {
   let demo = JSON.parse(JSON.stringify(this.damoList));
   this.addform.list.push(demo);
  },
  init() {
   //  this.getRoomTypeList()
   this.addform = {
    list: [{ beg: null, end: null, floorType: null, elevatorSorts: null }],
   };
   setTimeout(() => {
    this.$refs.addform.clearValidate();
   }, 10);
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 padding: 30px 30px 0;
}
.changeFloor {
}
</style>


