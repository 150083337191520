var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            { attrs: { shadow: "always" } },
            [
              _c(
                "el-form",
                { staticClass: "topS", attrs: { "label-width": "50px" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _vm.showadd
                            ? _c("el-button", { on: { click: _vm.addBtn } }, [
                                _vm._v("新增"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.openpopup },
                            },
                            [_vm._v("门店初始化")]
                          ),
                          _vm.showdelete
                            ? _c(
                                "el-button",
                                { on: { click: _vm.batchesDelete } },
                                [_vm._v("批量删除")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.seekData.brandId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.seekData, "brandId", $$v)
                                    },
                                    expression: "seekData.brandId",
                                  },
                                },
                                _vm._l(
                                  _vm.brandSelectData,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.brandName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "省份" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.seekData.provinceName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.seekData,
                                        "provinceName",
                                        $$v
                                      )
                                    },
                                    expression: "seekData.provinceName",
                                  },
                                },
                                _vm._l(
                                  _vm.provinceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店" } },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入门店名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.seekData,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "seekData.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "门店编号",
                                "label-width": "80px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "my-input",
                                attrs: {
                                  placeholder: "请输入门店编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.seekData.hotelCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.seekData,
                                      "hotelCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "seekData.hotelCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "reset-button", attrs: { span: 6 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { shadow: "always" },
            },
            [
              _c(
                "div",
                { staticClass: "my-header-table" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        height: _vm.height,
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brandName",
                          label: "品牌",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "门店", width: "220" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "门店ID", width: "80" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hotelCode",
                          label: "门店编号",
                          width: "100",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "region", label: "区域", width: "100" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "provinceName",
                          label: "省份",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "地址" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.provinceStr) +
                                      _vm._s(scope.row.address)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "开业状态", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  _vm._l(
                                    _vm.statusList,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row.status == item.value,
                                              expression:
                                                "scope.row.status == item.value",
                                            },
                                          ],
                                          key: index,
                                          attrs: {
                                            size: "mini",
                                            type: item.type,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.lable))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "小度_HOTELID",
                          prop: "duerosHotelid",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "TCL_HOTELID",
                          prop: "tclHotelid",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "300" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "many" },
                                  [
                                    _vm.showupdate
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        )
                                      : _vm._e(),
                                    _vm.showdelete
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm.showReset
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.roomStatusReset(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("重置客房监控数据")]
                                        )
                                      : _vm._e(),
                                    _vm.gppSync
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "warning",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.gppSyncOpt(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("同步资产到GPP")]
                                        )
                                      : _vm._e(),
                                    _vm.showQr
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showQrCode(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("未来控二维码")]
                                        )
                                      : _vm._e(),
                                    scope.row.duerosHotelid && _vm.showoper
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "success",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleOper(
                                                  scope.row,
                                                  "小度"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("小度同步")]
                                        )
                                      : _vm._e(),
                                    scope.row.tclHotelid && _vm.showoper
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "success",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleOper(
                                                  scope.row,
                                                  "TCL"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("TCL同步")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next, sizes, total, jumper",
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.seekData.pageSize,
                      "current-page": _vm.seekData.pageNum,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "编辑门店", visible: _vm.dialogFormVisible2 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editData",
              attrs: {
                model: _vm.editData,
                rules: _vm.myrules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门店名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.editData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店编号", prop: "hotelCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.editData.hotelCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "hotelCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.hotelCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PMS门店编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.editData.pmsHotelId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "pmsHotelId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.pmsHotelId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地区范围", prop: "region" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changeRegionEdit },
                      model: {
                        value: _vm.editData.region,
                        callback: function ($$v) {
                          _vm.$set(_vm.editData, "region", $$v)
                        },
                        expression: "editData.region",
                      },
                    },
                    _vm._l(_vm.regionsType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "selectedOptions1" } },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("el-cascader", {
                          attrs: {
                            size: "large",
                            options: _vm.newOptionsEdit,
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.editData.selectedOptions1,
                            callback: function ($$v) {
                              _vm.$set(_vm.editData, "selectedOptions1", $$v)
                            },
                            expression: "editData.selectedOptions1",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "如：XX大道XX路XX号" },
                    model: {
                      value: _vm.editData.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.editData, "address", $$v)
                      },
                      expression: "editData.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开业状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.editData, "status", $$v)
                        },
                        expression: "editData.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.lable, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小度_HOTELID" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "15" },
                    model: {
                      value: _vm.editData.duerosHotelid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "duerosHotelid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.duerosHotelid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "TCL_HOTELID" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "15" },
                    model: {
                      value: _vm.editData.tclHotelid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "tclHotelid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.tclHotelid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "wifi账号", prop: "wifiSsid" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "15" },
                    model: {
                      value: _vm.editData.wifiSsid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "wifiSsid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.wifiSsid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "wifi密码", prop: "wifiPassword" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "15" },
                    model: {
                      value: _vm.editData.wifiPassword,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editData,
                          "wifiPassword",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editData.wifiPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.editData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.editData, "description", $$v)
                      },
                      expression: "editData.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmEditD("editData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: {
            title: "新增",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "门店", name: "room" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("门店"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addform",
                        attrs: {
                          model: _vm.addform,
                          rules: _vm.myrules,
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌", prop: "brandId" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.addform.brandId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform, "brandId", $$v)
                                  },
                                  expression: "addform.brandId",
                                },
                              },
                              _vm._l(
                                _vm.brandSelectData,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.brandName,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店名称", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.addform.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addform,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addform.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店编号", prop: "hotelCode" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.addform.hotelCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addform,
                                    "hotelCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addform.hotelCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "PMS门店编号", prop: "pmsHotelId" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.addform.pmsHotelId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addform,
                                    "pmsHotelId",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addform.pmsHotelId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地区范围", prop: "region" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: "",
                                },
                                on: { change: _vm.changeRegionAdd },
                                model: {
                                  value: _vm.addform.region,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform, "region", $$v)
                                  },
                                  expression: "addform.region",
                                },
                              },
                              _vm._l(_vm.regionsType, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地址", prop: "selectedOptions" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                size: "large",
                                options: _vm.newOptionsAdd,
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.addform.selectedOptions,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "selectedOptions", $$v)
                                },
                                expression: "addform.selectedOptions",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "详细地址", prop: "address" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "如：XX大道XX路XX号" },
                              model: {
                                value: _vm.addform.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "address", $$v)
                                },
                                expression: "addform.address",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "开业状态", prop: "status" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.addform.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform, "status", $$v)
                                  },
                                  expression: "addform.status",
                                },
                              },
                              _vm._l(_vm.statusList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.lable,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "描述" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.addform.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "description", $$v)
                                },
                                expression: "addform.description",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.loading },
                            on: {
                              click: function ($event) {
                                return _vm.confirmAdd("addform")
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-tab-pane",
                { attrs: { label: "建筑", name: "building" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-office-building" }),
                    _vm._v("建筑\n        "),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "addform1",
                          attrs: {
                            model: _vm.addform1,
                            rules: _vm.myrules,
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "名称", prop: "buildingName" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入内容" },
                                model: {
                                  value: _vm.addform1.buildingName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addform1,
                                      "buildingName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addform1.buildingName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "描述" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 5,
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.addform1.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform1, "description", $$v)
                                  },
                                  expression: "addform1.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.addBuildingBtn,
                              expression: "addBuildingBtn",
                            },
                          ],
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.addBuilding("addform1")
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-tab-pane", { attrs: { label: "楼层", name: "floor" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("楼层\n        "),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addform2",
                        attrs: { model: _vm.addform2, rules: _vm.myrules },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "楼层" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "inline-flex",
                                "flex-direction": "row",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: { min: 1, "controls-position": "right" },
                                model: {
                                  value: _vm.addform2.beg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform2, "beg", $$v)
                                  },
                                  expression: "addform2.beg",
                                },
                              }),
                              _c(
                                "span",
                                { staticStyle: { margin: "0 20px" } },
                                [_vm._v("至")]
                              ),
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: { min: 1, "controls-position": "right" },
                                model: {
                                  value: _vm.addform2.end,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addform2, "end", $$v)
                                  },
                                  expression: "addform2.end",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "描述" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.addform2.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform2, "description", $$v)
                                },
                                expression: "addform2.description",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.addFloorBtn,
                            expression: "addFloorBtn",
                          },
                        ],
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.loading },
                            on: {
                              click: function ($event) {
                                return _vm.addFloor("addform2")
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("addView", {
        ref: "add",
        attrs: {
          roomTypeList: _vm.roomTypeList,
          brandSelectData: _vm.brandSelectData,
          eqTypeSelectData: _vm.eqTypeSelectData,
          SceneTypes: _vm.SceneTypes,
        },
      }),
      _c("qrcode", { ref: "qrcode" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }