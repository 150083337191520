var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c("el-form-item", { attrs: { label: "集团/品牌" } }, [
            _vm._v("\n   " + _vm._s(_vm.formData.brandName) + "\n  "),
          ]),
          _c("el-form-item", { attrs: { label: "门店" } }, [
            _vm._v("\n   " + _vm._s(_vm.formData.hotelName) + "\n  "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "房型" } },
            _vm._l(_vm.formData.equipmentList, function (iiem2, i) {
              return _c("span", { key: i }, [
                _vm._v(
                  "\n    " +
                    _vm._s(iiem2.roomTypeName) +
                    _vm._s(
                      _vm.formData.equipmentList.length - 1 > i ? " , " : ""
                    ) +
                    "\n   "
                ),
              ])
            }),
            0
          ),
          _c(
            "el-form-item",
            { attrs: { label: "楼层" } },
            _vm._l(_vm.formData.floorList, function (item, index) {
              return _c("span", { key: index }, [
                _vm._v(
                  "\n    " +
                    _vm._s(item.beg) +
                    " - " +
                    _vm._s(item.end) +
                    _vm._s(
                      _vm.formData.floorList.length - 1 > index ? " , " : ""
                    ) +
                    "\n   "
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.formData.equipmentList, function (item, idx) {
          return _c(
            "el-tab-pane",
            { key: item.value, attrs: { label: item.roomTypeName } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: item.equipmentInfoList, height: 300 },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "mainframeType", label: "主机类型" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "eqType", label: "设备类型" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "eqName", label: "设备名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确定要删除吗" },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.handleDelete(scope.$index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        size: "mini",
                                        type: "danger",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer ",
          staticStyle: { "margin-top": "30px" },
        },
        [
          _c("el-button", { on: { click: _vm.topPage } }, [_vm._v("上一步")]),
          _c(
            "el-popconfirm",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { title: "确定添加了吗" },
              on: { confirm: _vm.confirmAdd },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    loading: _vm.loading,
                    size: "mini",
                    type: "primary",
                  },
                  slot: "reference",
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }