var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: {
            model: _vm.addform,
            rules: _vm.myrules,
            "label-width": "120px",
          },
        },
        [
          !_vm.user.brandId
            ? _c(
                "el-form-item",
                { attrs: { label: "集团/品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "brandId", $$v)
                        },
                        expression: "addform.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "门店", prop: "hotelName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容", maxlength: "15" },
                model: {
                  value: _vm.addform.hotelName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "hotelName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.hotelName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "门店编号", prop: "hotelCode" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.addform.hotelCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "hotelCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.hotelCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "PMS门店编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.addform.pmsHotelId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "pmsHotelId",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.pmsHotelId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主机类型", prop: "mainframeType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.addform.mainframeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.addform, "mainframeType", $$v)
                    },
                    expression: "addform.mainframeType",
                  },
                },
                _vm._l(_vm.hostType, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "房型", prop: "roomType" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.addform.roomType,
                    callback: function ($$v) {
                      _vm.$set(_vm.addform, "roomType", $$v)
                    },
                    expression: "addform.roomType",
                  },
                },
                _vm._l(_vm.roomTypeList, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.value } },
                    [
                      item.type == "input"
                        ? _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "请输入内容",
                              size: "mini",
                              maxlength: "15",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.addCustomRoomType(item.lable, index)
                              },
                            },
                            model: {
                              value: item.lable,
                              callback: function ($$v) {
                                _vm.$set(
                                  item,
                                  "lable",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item.lable",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(item.lable))]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "建筑数", prop: "buildCount" } },
            [
              _c("el-input-number", {
                attrs: { precision: 0, min: 1, "controls-position": "right" },
                model: {
                  value: _vm.addform.buildCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "buildCount", $$v)
                  },
                  expression: "addform.buildCount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer " },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.confirmAdd("addform")
                },
              },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }