<template>
  <div class="my-box">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form label-width="50px" class="topS">
          <el-row :gutter="10">
            <el-col :span="5">
              <!-- v-if="initPopup"  -->
              <el-button @click="addBtn" v-if="showadd">新增</el-button>
              <el-button size="mini" @click="openpopup">门店初始化</el-button>
              <el-button @click="batchesDelete" v-if="showdelete"
                >批量删除</el-button
              >
            </el-col>
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="省份">
                <el-select
                  v-model="seekData.provinceName"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in provinceList"
                    :key="index"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="门店">
                <el-input
                  placeholder="请输入门店名称"
                  v-model.trim="seekData.name"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="门店编号" label-width="80px">
                <el-input
                  placeholder="请输入门店编号"
                  v-model.trim="seekData.hotelCode"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always" v-loading="loading">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="门店"
              width="220"
            ></el-table-column>
            <el-table-column
              prop="id"
              label="门店ID"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="hotelCode"
              label="门店编号"
              width="100"
              sortable
            ></el-table-column>
            <el-table-column
              prop="region"
              label="区域"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="provinceName"
              label="省份"
              width="120"
            ></el-table-column>

            <el-table-column label="地址">
              <template slot-scope="scope">
                <span>{{ scope.row.provinceStr }}{{ scope.row.address }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开业状态" width="80">
              <template slot-scope="scope">
                <div>
                  <el-tag
                    size="mini"
                    v-for="(item, index) in statusList"
                    v-show="scope.row.status == item.value"
                    :key="index"
                    :type="item.type"
                    >{{ item.lable }}</el-tag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="小度_HOTELID"
              prop="duerosHotelid"
              width="120"
            ></el-table-column>
            <el-table-column
              label="TCL_HOTELID"
              prop="tclHotelid"
              width="120"
            ></el-table-column>

            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <div class="many">
                  <!-- 编辑按钮 -->
                  <el-button
                    v-if="showupdate"
                    type="primary"
                    size="mini"
                    @click="handleEdit(scope.row)"
                    >修改</el-button
                  >

                  <!-- 删除按钮 -->
                  <el-button
                    v-if="showdelete"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    v-if="showReset"
                    type="danger"
                    size="mini"
                    @click="roomStatusReset(scope.row)"
                    >重置客房监控数据</el-button
                  >
                  <el-button
                    v-if="gppSync"
                    type="warning"
                    size="mini"
                    @click="gppSyncOpt(scope.row)"
                    >同步资产到GPP</el-button
                  >

                  <el-button
                    v-if="showQr"
                    type="info"
                    size="mini"
                    @click="showQrCode(scope.row)"
                    >未来控二维码</el-button
                  >
                  <el-button
                    v-if="scope.row.duerosHotelid && showoper"
                    size="mini"
                    type="success"
                    @click="handleOper(scope.row, '小度')"
                    >小度同步</el-button
                  >
                  <el-button
                    v-if="scope.row.tclHotelid && showoper"
                    size="mini"
                    type="success"
                    @click="handleOper(scope.row, 'TCL')"
                    >TCL同步</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
    <!-- 编辑的弹框 -->
    <el-dialog
      title="编辑门店"
      :visible.sync="dialogFormVisible2"
      class="astrict"
    >
      <el-form
        :model="editData"
        ref="editData"
        :rules="myrules"
        label-width="120px"
      >
        <el-form-item label="门店名称" prop="name">
          <el-input
            v-model.trim="editData.name"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店编号" prop="hotelCode">
          <el-input
            v-model.trim="editData.hotelCode"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>

        <el-form-item label="PMS门店编号">
          <el-input
            v-model.trim="editData.pmsHotelId"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="地区范围" prop="region">
          <el-select
            v-model="editData.region"
            placeholder="请选择"
            clearable
            filterable
            @change="changeRegionEdit"
          >
            <el-option
              v-for="(item, index) in regionsType"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="selectedOptions1">
          <template>
            <div class="block">
              <el-cascader
                size="large"
                :options="newOptionsEdit"
                v-model="editData.selectedOptions1"
                clearable
                filterable
              ></el-cascader>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="editData.address"
            placeholder="如：XX大道XX路XX号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开业状态" prop="status">
          <el-select v-model="editData.status" placeholder="请选择">
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.lable"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小度_HOTELID">
          <el-input
            type="text"
            v-model.trim="editData.duerosHotelid"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="TCL_HOTELID">
          <el-input
            type="text"
            v-model.trim="editData.tclHotelid"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="wifi账号" prop="wifiSsid">
          <el-input
            type="text"
            v-model.trim="editData.wifiSsid"
            maxlength="15"
          ></el-input>
        </el-form-item>
        <el-form-item label="wifi密码" prop="wifiPassword">
          <el-input
            type="text"
            v-model.trim="editData.wifiPassword"
            maxlength="15"
          ></el-input>
        </el-form-item>

        <el-form-item label="描述">
          <el-input
            v-model="editData.description"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmEditD('editData')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增的弹框 -->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      class="astrict"
      :before-close="handleClose"
    >
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="门店" name="room">
          <span slot="label">门店</span>
          <div>
            <el-form
              :model="addform"
              ref="addform"
              :rules="myrules"
              :label-width="formLabelWidth"
            >
              <el-form-item label="品牌" prop="brandId">
                <el-select v-model="addform.brandId" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="门店名称" prop="name">
                <el-input
                  v-model.trim="addform.name"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="门店编号" prop="hotelCode">
                <el-input
                  v-model.trim="addform.hotelCode"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item label="PMS门店编号" prop="pmsHotelId">
                <el-input
                  v-model.trim="addform.pmsHotelId"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="地区范围" prop="region">
                <el-select
                  v-model="addform.region"
                  placeholder="请选择"
                  @change="changeRegionAdd"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in regionsType"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地址" prop="selectedOptions">
                <!-- <template>
         <div class="block"> -->
                <el-cascader
                  size="large"
                  :options="newOptionsAdd"
                  v-model="addform.selectedOptions"
                  clearable
                  filterable
                ></el-cascader>
                <!-- </div>
        </template> -->
              </el-form-item>
              <el-form-item label="详细地址" prop="address">
                <el-input
                  v-model="addform.address"
                  placeholder="如：XX大道XX路XX号"
                ></el-input>
              </el-form-item>
              <el-form-item label="开业状态" prop="status">
                <el-select v-model="addform.status" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.lable"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="描述">
                <el-input
                  v-model="addform.description"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <!-- <el-button @click="abrogateAdd">取 消</el-button> -->
              <el-button
                type="primary"
                @click="confirmAdd('addform')"
                :loading="loading"
                >确 定</el-button
              >
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="建筑" name="building">
          <span slot="label">
            <i class="el-icon-office-building"></i>建筑
          </span>
          <div>
            <el-form
              :model="addform1"
              ref="addform1"
              :rules="myrules"
              :label-width="formLabelWidth"
            >
              <el-form-item label="名称" prop="buildingName">
                <el-input
                  v-model.trim="addform1.buildingName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item label="描述">
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="addform1.description"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="addBuildingBtn">
              <!-- <el-button @click="abrogateAdd">取 消</el-button> -->
              <el-button
                type="primary"
                @click="addBuilding('addform1')"
                :loading="loading"
                >确 定</el-button
              >
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="楼层" name="floor">
          <span slot="label">
            <i class="el-icon-office-building"></i>楼层
          </span>
          <div>
            <el-form :model="addform2" ref="addform2" :rules="myrules">
              <el-form-item label="楼层">
                <div
                  style="
                    display: inline-flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <el-input-number
                    style="width: 100%"
                    v-model="addform2.beg"
                    :min="1"
                    controls-position="right"
                  ></el-input-number>
                  <span style="margin: 0 20px">至</span>
                  <el-input-number
                    style="width: 100%"
                    v-model="addform2.end"
                    :min="1"
                    controls-position="right"
                  ></el-input-number>
                </div>
              </el-form-item>

              <el-form-item label="描述">
                <el-input
                  v-model="addform2.description"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="addFloorBtn">
              <!-- <el-button @click="abrogateAdd">取 消</el-button> -->
              <el-button
                type="primary"
                @click="addFloor('addform2')"
                :loading="loading"
                >确 定</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <addView
      ref="add"
      :roomTypeList="roomTypeList"
      :brandSelectData="brandSelectData"
      :eqTypeSelectData="eqTypeSelectData"
      :SceneTypes="SceneTypes"
    />
    <qrcode ref="qrcode" />
  </div>
</template>
<script>
import addView from "./addView";
import qrcode from "./qrcode";
import {
  hotelOper,
  hotelOperTcl,
  getHotelList,
  addHotel,
  delHotel,
  roomStatusReset,
  gppSyncOpt,
  getBrandSelect,
  editHotel,
  getHotelSeek,
  hotelNameVerify,
  buildingNameVerify,
  addBuilding,
  addFloor,
  getBuildinglList,
  getEqTypeSelect,
  getSceneSelect,
} from "@/api";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { returnNewArea } from "@/api/common.js";

export default {
  // name:"md",
  components: {
    addView,
    qrcode,
  },
  data() {
    /* 门店名是否重复校验 */
    let Verify = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("不能为空"));
      } else {
        if (this.editData.id != null) {
          hotelNameVerify({
            name: value,
            id: this.editData.id,
            brandId: this.editData.brandId,
          }).then((res) => {
            if (res.data.data) {
              callback();
            } else {
              callback(new Error("你输入的已存在，请重新输入"));
            }
          });
        } else {
          if (this.addform.brandId != null) {
            hotelNameVerify({
              name: value,
              id: this.editData.id,
              brandId: this.addform.brandId,
            }).then((res) => {
              if (res.data.data) {
                callback();
              } else {
                callback(new Error("你输入的已存在，请重新输入"));
              }
            });
          } else {
            this.$message({
              type: "error",
              message: "请选择品牌",
            });
          }
        }
      }
    };

    // 门店编号重复校验
    let VerifyCode = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("不能为空"));
      } else {
        hotelNameVerify({ hotelCode: value, id: this.editData.id }).then(
          (res) => {
            if (res.data.data) {
              callback();
            } else {
              callback(new Error("你输入的已存在，请重新输入"));
            }
          }
        );
      }
    };

    /* 建筑名是否重复校验 */
    let VerifyBuildingName = (rule, value, callback) => {
      if (value == undefined || value === null || value === "") {
        callback(new Error("不能为空"));
      } else {
        buildingNameVerify({
          hotelId: this.addform.hotelId,
          buildingName: value,
          id: this.editData.id,
        }).then((res) => {
          if (res.data) {
            callback();
          } else {
            callback(new Error("你输入的已存在，请重新输入"));
          }
        });
      }
    };
    return {
      SceneTypes: null,
      eqTypeSelectData: null,
      provinceList: regionData,
      newOptionsAdd: [],
      newOptionsEdit: [],
      regionsType: [
        "华北区",
        "东北区",
        "华东区",
        "华中区",
        "华南区",
        "西南区",
        "西北区",
      ],
      height: null,
      activeName: "room",
      addBuildingBtn: false,
      addFloorBtn: false,
      multipleSelection: [],
      loading: false,
      // 门店列表数据
      tableData: null,
      dialogFormVisible2: null,
      dialogFormVisible: null,
      foldData: false,
      formLabelWidth: "100px",
      regions: [
        {
          value: "",
          name: "",
        },
      ],
      // 查询的数据
      total: null,
      seekData: {
        brandId: null,
        name: null,
        hotelCode: null,
        provinceName: null,
        pageSize: 10,
        pageNum: 1,
      },
      // 新增门店
      addform: {
        brandId: null, //品牌ID
        name: null, //门店名称
        hotelCode: null, //门店编号
        pmsHotelId: null,
        region: null, //区域
        description: null, //门店描述
        address: null, //详细地址
        selectedOptions: null, //省
        status: null,
        // description: null,   //区
      },

      // 新增建筑
      addform1: {
        brandId: null, //建筑id
        description: null, //建筑描述
        hotelId: null, //门店ID
        buildingName: null, //建筑名称
        description: null, //建筑描述
      },
      // 新增客房
      addform2: {
        hotelId: null,
        brandId: null,
        beg: null,
        end: null,
        description: null, //建筑描述
        buildingId: null, //建筑ID
      },
      brandSelectData: null,
      hotelSelectData: null,
      buildingSelectData: null,
      // 编辑
      editData: {
        name: null, //门店名称
        hotelCode: null, //门店编号
        pmsHotelId: null,
        description: null, //门店描述
        id: null, //门店ID
        brandId: null, //品牌ID
        address: null, //详细地址
        province: null, //省
        region: null,
        status: null,
        duerosHotelid: null,
        wifiSsid: null,
        wifiPassword: null,
        selectedOptions1: [],
      },
      myrules: {
        name: [{ required: true, validator: Verify, trigger: "blur" }],
        wifiSsid: [{ required: true, message: "请输入", trigger: "blur" }],
        wifiPassword: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 8,
            max: 15,
            message: "长度在 8 到 15 个字符",
            trigger: "blur",
          },
        ],
        hotelCode: [{ required: true, validator: VerifyCode, trigger: "blur" }],
        address: [{ required: true, message: "请选择", trigger: "change" }],
        buildingName: [
          { required: true, validator: VerifyBuildingName, trigger: "blur" },
        ],
        brandId: [{ required: true, message: "请选择", trigger: "change" }],
        status: [{ required: true, message: "请选择", trigger: "change" }],
        hotelId: [{ required: true, message: "请选择", trigger: "change" }],
        buildingId: [{ required: true, message: "请选择", trigger: "change" }],
        selectedOptions1: [
          { required: true, message: "请选择地址", trigger: "blur" },
        ],
        selectedOptions: [
          { required: true, message: "请选择地址", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择区域", trigger: "change" }],
      },

      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  computed: {
    statusList() {
      return this.$store.state.operationState;
    },
    hostType() {
      return this.$store.state.hostType;
    },
    bySelfCode() {
      return this.$store.state.bySelfCode;
    },
    clearSceneHas() {
      return this.$store.state.clearSceneHas;
    },
    r3000() {
      return this.$store.state.r3000;
    },

    initPopup() {
      return this.userJurisdiction.includes("initPopup:btn");
    },
    // 权限
    showadd() {
      return this.userJurisdiction.includes("hotel:add");
    },
    showdelete() {
      return this.userJurisdiction.includes("hotel:delete");
    },
    showReset() {
      return this.userJurisdiction.includes("roomStatus:reset");
    },
    showQr() {
      return this.userJurisdiction.includes("hotel:qrCode");
    },
    gppSync() {
      return this.userJurisdiction.includes("hotel:gppSync");
    },
    showupdate() {
      return this.userJurisdiction.includes("hotel:update");
    },
    showoper() {
      return this.userJurisdiction.includes("dueros:add");
    },
    roomTypeList() {
      return this.$store.state.roomTypeList;
    },
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    // 调用初始化表格数据方法
    this.initList(this.seekData);
    this.initialize();
  },
  methods: {
    gppSyncOpt(row) {
      this.$confirm("此操作将客房和设备数据同步到锦江GPP, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          gppSyncOpt(row.id).then((res) => {
            console.log(res.data)
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消同步",
          });
        });
    },
    roomStatusReset(row) {
      this.$confirm("此操作将重置客房监控数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          roomStatusReset(row.id).then((res) => {
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    showQrCode(row) {
      this.$refs.qrcode.openSet(row);
    },
    openpopup() {
      this.$refs.add.openSet();
    },
    changeRegionAdd(val) {
      if (val) {
        this.addform.selectedOptions = [];
        this.newOptionsAdd = returnNewArea(val, regionData);
      } else {
        this.newOptionsAdd = regionData;
      }
    },
    changeRegionEdit(val) {
      if (val) {
        this.editData.selectedOptions1 = [];
        this.newOptionsEdit = returnNewArea(val, regionData);
      } else {
        this.newOptionsEdit = regionData;
      }
    },
    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getHotelList(obj)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            if (res.data.data.rows != false) {
              res.data.data.rows.forEach((item) => {
                item.provinceArr = item.province
                  ? item.province.split(",")
                  : [];
                item.provinceStr = item.province
                  ? item.province.replace(/,/g, "")
                  : ""; //取消字符串中出现的所有逗号
              });
            }
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;

            if (this.seekData.pageNum > 1 && res.data.data.rows == false) {
              this.seekData.pageNum = this.seekData.pageNum - 1;
              this.initList(this.seekData);
            }
            // 给totalNum赋值
            // this.totalNum = res.data.data.total
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /* 初始化下拉框 */
    // 获取品牌下拉框
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
      getEqTypeSelect().then((res) => {
        if (res.status === 200) {
          //   let list=res.data.data
          //  this.eqTypeSelectData= list.filter(item=>item.valuee.indexOf('WR3000')!=-1)
          this.eqTypeSelectData = res.data.data;
          // console.log(res)
        }
      });
      getSceneSelect().then((res) => {
        if (res.status === 200) {
          this.SceneTypes = res.data.data;
          this.clearSceneTypes = this.SceneTypes.filter((item) => {
            return this.clearSceneHas.includes(item.keyy);
          });
          // console.log(res)
        }
      });
    },
    // 获取门店下拉框
    selectOne(id) {
      // console.log(id)
      this.addform.hotelId = null;
      this.addform.buildingId = null;
      if (!id) return;
      getHotelSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店",
            type: "warning",
          });
        }
      });
    },
    // 获取建筑下拉框
    selectTwo(id) {
      // console.log(id)
      getBuildingSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data) {
          this.addform.buildingId = null;
          this.buildingSelectData = res.data.data;
        } else {
          this.$message({
            message: "该门店下没有建筑",
            type: "warning",
          });
        }
      });
    },
    /* 展开搜索头部 */
    fold() {
      this.foldData = !this.foldData;
      if (this.foldData) {
        this.initialize();
      }
    },

    /* 新增弹窗 */
    // 获取品牌下拉框
    addBtn() {
      this.dialogFormVisible = true;
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    // 门店新增修改数据封装
    putData(obj, id) {
      let putData = {};
      putData.brandId = obj.brandId;
      putData.name = obj.name;
      putData.hotelCode = obj.hotelCode;
      putData.pmsHotelId = obj.pmsHotelId;
      putData.address = obj.address;
      putData.description = obj.description;
      putData.region = obj.region;
      putData.status = obj.status;
      let provinc = [];
      if (obj.selectedOptions) {
        obj.selectedOptions.forEach((item) => {
          provinc.push(CodeToText[item]);
        });
      }
      let provincn = provinc.join(",");
      putData.province = provincn;
      if (id) {
        putData.id = id;
      }
      return putData;
    },
    // 确定按钮
    confirmAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.putData(this.addform);
          this.loading = true;
          addHotel(data)
            .then((res) => {
              // console.log(res)
              this.loading = false;
              if (res.data.code == "000000") {
                this.initList(this.seekData);
                // this.dialogFormVisible = false;
                // this.reset()
                // this.dialogFormVisible = false
                this.addBuildingBtn = true;
                this.$message.success(res.data.message);
                getHotelList({
                  name: this.addform.name,
                  brandId: this.addform.brandId,
                }).then((res) => {
                  if (res.status === 200) {
                    console.log(res);
                    if (res.data.data.rows != false) {
                      if (res.data.data.rows.length == 1) {
                        this.addform.hotelId = res.data.data.rows[0].id;
                      } else {
                        this.$message({
                          type: "error",
                          message: "多个门店信息，请联系管理员！！",
                        });
                      }
                    }
                  }
                });
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 添加建筑
    addBuilding(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.seekData.name = this.addform.name;
          this.seekData.brandId = this.addform.brandId;
          getHotelList(
            Object.assign(this.seekData, { brandId: this.addform.brandId })
          ).then((res) => {
            if (res.status === 200) {
              if (res.data.data.rows != false) {
                if (res.data.data.rows.length == 1) {
                  this.addform1.brandId = res.data.data.rows[0].brandId;
                  this.addform1.hotelId = res.data.data.rows[0].id;
                  addBuilding(this.addform1)
                    .then((res) => {
                      this.loading = false;
                      if (res.data.code == "000000") {
                        this.$message.success(res.data.message);
                        this.addFloorBtn = true;
                      } else {
                        this.$message.error(res.data.message);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  this.$message({
                    type: "error",
                    message: "多个门店信息，请联系管理员！！",
                  });
                }
              } else {
                this.$message({
                  type: "info",
                  message: "无法添加，请联系管理员",
                });
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 添加楼层
    addFloor(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addform2.beg > this.addform2.end) {
            this.$message.error("请正确输入起止楼层");
            return;
          }
          this.loading = true;
          getBuildinglList({
            buildingName: this.addform1.buildingName,
            brandId: this.addform1.brandId,
            hotelId: this.addform1.hotelId,
          }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
              // this.tableData = res.data.rows
              // this.total = res.data.total
              if (res.data.data.rows != false) {
                this.addform2.buildingId = res.data.data.rows[0].id;
                if (res.data.data.rows.length == 1) {
                  delete this.addform2.brandId;
                  delete this.addform2.hotelId;
                  addFloor(this.addform2)
                    .then((res) => {
                      this.loading = false;
                      if (res.data.code == "000000") {
                        this.$message.success(res.data.message);
                      } else {
                        this.$message.error(res.data.message);
                      }
                      this.handleClose();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  this.$message({
                    type: "error",
                    message: "联系管理员，楼层过多",
                  });
                }
              } else {
                this.$message({
                  type: "info",
                  message: "无法添加，请联系管理员",
                });
              }
              this.loading = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleClose() {
      // 清空所有弹框信息
      this.$nextTick(() => {
        this.$refs.addform.resetFields();
        this.$refs.addform1.resetFields();
        this.dialogFormVisible = false;
        this.selectedOptions = [];
        this.activeName = "room";
        this.clearData();
      });
    },

    clearData() {
      this.addform.description =
        this.addform1.description =
        this.addform2.description =
        this.addform2.buildingId =
        this.addform1.hotelId =
        this.addform1.brandId =
        this.addform.address =
          null;
    },

    // 取消按钮
    abrogateAdd() {
      this.dialogFormVisible = false;
    },

    // 门店删除
    handleDelete(row) {
      console.log(row);
      this.$confirm("此操作将永久删除该门店, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 点击确定执行的操作
          console.log(row.id);
          delHotel(row.id).then((res) => {
            console.log(res);
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
              this.initList(this.seekData);
              // this.reset()
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    batchesDelete() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的用户ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.id + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.$confirm("此操作将永久删除所选择门店, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            delHotel(number).then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
                // this.reset()
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.warning("请先选择要删除的数据");
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },

    handleOper(val, type) {
      this.$confirm(`该酒店将同步到${type}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == "小度") {
            this.hotelOper({ hotelId: val.duerosHotelid });
          } else {
            this.hotelOperTcl({ hotelId: val.tclHotelid });
          }
        })
        .catch(() => {});
    },
    hotelOper(data) {
      this.loading = true;
      hotelOper(data)
        .then((r) => {
          console.log(r);
          this.loading = false;
          if (r.data.code == "000000") {
            this.$message.success(r.data.message);
          } else {
            this.$message.error(r.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    hotelOperTcl(data) {
      this.loading = true;
      hotelOperTcl(data)
        .then((r) => {
          this.loading = false;
          if (r.data.code == "000000") {
            this.$message.success(r.data.message);
          } else {
            this.$message.error(r.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 编辑门店
    handleEdit(index, row) {
      console.log(index);
      this.editData = index;
      let code = this.convertTextToCode(...index.provinceArr);
      console.log(code);
      this.newOptionsEdit = regionData;
      let codeArr = code.split(", ");
      this.editData.selectedOptions1 = codeArr;

      this.dialogFormVisible2 = true;
    },

    //通过省市区获取对应编码
    convertTextToCode(provinceText, cityText, regionText) {
      let code = "";
      if (provinceText && TextToCode[provinceText]) {
        const province = TextToCode[provinceText];
        code += province.code + ", ";
        if (cityText && province[cityText]) {
          const city = province[cityText];
          code += city.code + ", ";
          if (regionText && city[regionText]) {
            code += city[regionText].code;
          }
        }
      }
      return code;
    },

    // 编辑门店确认
    confirmEditD(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let provinc = [];
          this.editData.selectedOptions1.forEach((item) => {
            provinc.push(CodeToText[item]);
          });
          let provincn = provinc.join(",");
          this.editData.province = provincn;
          let newform = JSON.parse(JSON.stringify(this.editData));
          delete newform.selectedOptions1;
          console.log(newform);
          editHotel(newform).then((res) => {
            this.loading = false;
            if (res.data.code == "000000") {
              this.$message.success(res.data.message);
              this.initList(this.seekData);
              // this.reset()
              this.dialogFormVisible2 = false;
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 查询按钮
    handleSearch() {
      // console.log(this.seekData.brandId)
      this.seekData.pageNum = 1;
      if (this.seekData.brandId || this.seekData.name) {
        this.loading = true;
        getHotelSeek(this.seekData)
          .then((res) => {
            this.loading = false;
            if (res.data.data.rows != false) {
              res.data.data.rows.forEach((item) => {
                item.provinceArr = item.province
                  ? item.province.split(",")
                  : [];
                item.provinceStr = item.province
                  ? item.province.replace(/,/g, "")
                  : ""; //取消字符串中出现的所有逗号
              });
            }
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.initList(this.seekData);
      }
    },
    //分页
    handleCurrentChange(cpage) {
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
    //重置
    reset() {
      this.seekData.brandId = null;
      this.seekData.name = null;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>
<style lang="scss" scoped>
.astrict {
  .el-select {
    width: 100%;
  }
  .el-cascader {
    width: 100%;
  }
}
.my-box {
  .my-input {
    max-width: 209px;
  }
  .my-iemt {
    span {
      font-size: 14px;
    }
    white-space: nowrap;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
