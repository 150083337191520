var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: { model: _vm.addform, "label-width": "60px" },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.changeActiveName },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.addform.equipmentList, function (item, idx) {
              return _c(
                "el-tab-pane",
                { key: item.value, attrs: { label: item.roomTypeName } },
                [
                  !item.equipmentInfoList || item.equipmentInfoList.length == 0
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("\n     - - 暂 无 设 备 - -\n    "),
                      ])
                    : _vm._e(),
                  _vm._l(item.equipmentInfoList, function (iiem2, i) {
                    return _c(
                      "el-tag",
                      {
                        key: i,
                        staticClass: "pointer",
                        attrs: {
                          "disable-transitions": false,
                          closable: "",
                          type: _vm.tagIndex == i ? "success" : "info",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.handleClose(i)
                          },
                          click: function ($event) {
                            return _vm.changeTag(i)
                          },
                        },
                      },
                      [_vm._v("\n     " + _vm._s(iiem2.eqName) + "\n    ")]
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("sbAdd", {
        ref: "sbAdd",
        attrs: {
          activeName: _vm.activeName,
          tagIndex: _vm.tagIndex,
          mainframeType: _vm.mainframeType,
          eqTypeSelectData: _vm.eqTypeSelectData,
          SceneTypes: _vm.SceneTypes,
        },
        on: {
          commitSbData: _vm.commitSbData,
          commitData3: _vm.commitData3,
          updateSbData: _vm.updateSbData,
          topPage: _vm.topPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }