<template>
 <div class="my-box">
  <el-form label-width="120px">
   <el-form-item label="集团/品牌">
    {{formData.brandName}}
   </el-form-item>
   <el-form-item label="门店">
    {{formData.hotelName}}
   </el-form-item>
   <el-form-item label="房型">
    <span v-for="(iiem2,i) in formData.equipmentList" :key="i">
     {{iiem2.roomTypeName}}{{formData.equipmentList.length-1>i?' , ':''}}
    </span>
   </el-form-item>
   <el-form-item label="楼层">
    <span v-for="(item,index) in formData.floorList" :key="index">
     {{item.beg}} - {{item.end}}{{formData.floorList.length-1>index?' , ':''}}
    </span>

   </el-form-item>
  </el-form>
  <el-tabs type="border-card" v-model="activeName">
   <el-tab-pane v-for="(item,idx) in formData.equipmentList" :key="item.value" :label="item.roomTypeName">
    <el-table :data="item.equipmentInfoList" :height="300" style="width: 100%">
     <el-table-column prop="mainframeType" label="主机类型">
      </el-table-column>
     <el-table-column prop="eqType" label="设备类型">
     </el-table-column>
     <el-table-column prop="eqName" label="设备名称">
     </el-table-column>
     <el-table-column label="操作" width="80">
      <template slot-scope="scope">

      
       <!-- 删除按钮 -->
       <el-popconfirm
            title="确定要删除吗"
            @confirm="handleDelete(scope.$index)"
          >
            <el-button slot="reference" size="mini" type="danger"  >删除</el-button>
          </el-popconfirm>
      
      </template>
     </el-table-column>
    </el-table>
   </el-tab-pane>
  </el-tabs>

  <div class="dialog-footer " style="margin-top:30px">
   <el-button @click="topPage">上一步</el-button>
   <el-popconfirm style="margin-left:10px"
            title="确定添加了吗"
            @confirm="confirmAdd"
          >
            <el-button slot="reference" :loading="loading" size="mini" type="primary" >确认</el-button>
          </el-popconfirm>
   <!-- <el-button @click="confirmAdd('addform')" :loading="loading">确认</el-button> -->
  </div>
 </div>
</template>
<script>
import {
  hotelInitAdd
} from "@/api";
export default {
 props: ["brandSelectData"],
 data() {
  return {
   activeName: 0,
   loading: false,
   formData: {},
  };
 },

 methods: {
  topPage() {
   this.$emit("topPage", this.formData, this.activeName);
  },
  //  handleEdit(i){

  //  },
  handleDelete(i) {
    console.log(this.activeName,'this.activeName',i);
    console.log(this.formData.equipmentList);
   if (this.formData.equipmentList[this.activeName].equipmentInfoList.length <= 1) {
    this.$message.warning("再删就没了！");
    return;
   }
   this.formData.equipmentList[this.activeName].equipmentInfoList.splice(i, 1);
  },

  getformData(data) {
   console.log(data);
   this.formData = JSON.parse(JSON.stringify(data));
   console.log(this.brandSelectData);
   if(this.formData.brandId){
     let brData = this.brandSelectData.find(
      (item) => item.id == this.formData.brandId
     );
     this.formData.brandName = brData.brandName;
   }
    delete this.formData.mainframeType
   
   console.log(this.formData);
  },
  confirmAdd() {
    console.log(this.formData);
    this.loading=true
    hotelInitAdd(this.formData).then(res=>{
       this.loading=false
      console.log(res);
      if(res.data.code=='000000'){
        this.$message.success('添加成功')
        this.$emit("init",'close')

      }else{
        this.$message.error(res.data.message)
      }
      //  this.$emit("init")
    }).catch(err=>{
       this.loading=false
    })
  
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 padding: 30px 30px 0;
}

</style>


