var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "门店初始化",
            top: "3vh",
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.stepActive, "finish-status": "success" } },
            _vm._l(_vm.steps, function (item, index) {
              return _c("el-step", { key: index, attrs: { title: item.label } })
            }),
            1
          ),
          _c("data1", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 0,
                expression: "stepActive==0",
              },
            ],
            ref: "brandForm",
            attrs: { brandSelectData: _vm.brandSelectData },
            on: { commitData1: _vm.commitData1 },
          }),
          _c("data2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 1,
                expression: "stepActive==1",
              },
            ],
            ref: "floorForm",
            on: { topPage: _vm.topPage, commitData2: _vm.commitData2 },
          }),
          _c("data3", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 2,
                expression: "stepActive==2",
              },
            ],
            ref: "data3",
            attrs: {
              mainframeType: _vm.mainframeType,
              equipmentList: _vm.equipmentList,
              eqTypeSelectData: _vm.eqList,
              SceneTypes: _vm.SceneTypes,
            },
            on: { topPage: _vm.topPage, commitData3: _vm.commitData3 },
          }),
          _c("configView", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 3,
                expression: "stepActive==3",
              },
            ],
            ref: "configView",
            attrs: {
              brandSelectData: _vm.brandSelectData,
              addform: _vm.addform,
            },
            on: { init: _vm.ininAllForm, topPage: _vm.topPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }