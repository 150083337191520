var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: {
            model: _vm.addform,
            rules: _vm.myrules,
            "label-width": "100px",
          },
        },
        _vm._l(_vm.addform.list, function (item, index) {
          return _c(
            "el-card",
            {
              key: index,
              staticStyle: { "margin-bottom": "20px", padding: "20px 0" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "楼层",
                            prop: "list." + index + ".beg",
                            rules: _vm.myrules.beg,
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.addform.list[index].beg,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform.list[index], "beg", $$v)
                              },
                              expression: "addform.list[index].beg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "至",
                            prop: "list." + index + ".end",
                            rules: _vm.myrules.end,
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.addform.list[index].end,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform.list[index], "end", $$v)
                              },
                              expression: "addform.list[index].end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: " ", "label-width": "20px" } },
                        [
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-plus",
                              circle: "",
                            },
                            on: { click: _vm.addFloor },
                          }),
                          index > 0
                            ? _c("el-button", {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-minus",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.minusFloor(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "楼层类型",
                            prop: "list." + index + ".floorType",
                            rules: _vm.myrules.floorType,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.addform.list[index].floorType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addform.list[index],
                                    "floorType",
                                    $$v
                                  )
                                },
                                expression: "addform.list[index].floorType",
                              },
                            },
                            _vm._l(_vm.floorTypes, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.lable, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "梯控顺序",
                            prop: "list." + index + ".elevatorSorts",
                            rules: _vm.myrules.elevatorSorts,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入梯控顺序" },
                            model: {
                              value: _vm.addform.list[index].elevatorSorts,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform.list[index],
                                  "elevatorSorts",
                                  $$v
                                )
                              },
                              expression: "addform.list[index].elevatorSorts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer " },
        [
          _c("el-button", { on: { click: _vm.topPage } }, [_vm._v("上一步")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.confirmAdd("addform")
                },
              },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }