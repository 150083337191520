<template>
 <div class="my-box">
  <el-dialog title="门店初始化" top="3vh" :close-on-click-modal="false" :visible.sync="showDialog" width="60%">
   <el-steps :active="stepActive" finish-status="success">
    <el-step :title="item.label" v-for="(item,index) in steps" :key="index"></el-step>

   </el-steps>

   <data1 v-show="stepActive==0" ref="brandForm"  :brandSelectData="brandSelectData" @commitData1="commitData1" />
   <data2 v-show="stepActive==1" ref="floorForm" @topPage="topPage" @commitData2="commitData2" />
   <data3 v-show="stepActive==2" ref="data3" @topPage="topPage" :mainframeType="mainframeType" :equipmentList="equipmentList" :eqTypeSelectData="eqList" :SceneTypes="SceneTypes" @commitData3="commitData3" />
  <configView :brandSelectData="brandSelectData" ref="configView" @init="ininAllForm" @topPage="topPage" :addform="addform" v-show="stepActive==3" />
  </el-dialog>
 </div>
</template>
<script>
import { trackDetail, getHotelSelect } from "@/api";
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";
import data1 from "./data1";
import data2 from "./data2";
import data3 from "./data3";
import configView from "./configView";

export default {
 props: ["brandSelectData", "eqTypeSelectData", "SceneTypes","roomTypeList"],
 components: {
  data1,
  data2,
  data3,
  configView,
  // changeCard,
  // importMessage
 },
 data() {
  return {
       r_Name:"R3000",
       mainframeType:null,
   stepActive:0,
   equipmentList:null,
  
   addform: {},
   steps: [
    {
     label: "酒店",
    },
    {
     label: "楼层",
    },
    {
     label: "客房模板",
    },
    {
     label: "核对",
    },
   ],
   showDialog: false,
  };
 },
   computed:{
        eqList(){
    if(this.eqTypeSelectData){
      if(this.addform.mainframeType == this.r_Name)  return this.eqTypeSelectData.filter(item=>item.valuee.indexOf(this.r_Name)>-1)
      return   this.eqTypeSelectData.filter(item=>item.valuee.indexOf(this.r_Name)==-1)

    }
   return this.eqTypeSelectData
  },
 
   },
 methods: {
   topPage(data,activeName){
      this.stepActive--
      if(data){
         this.$refs.data3.getFormData(data,activeName);
      }
   },
  commitData1(data) {
   console.log(data, "-commitData1");
   this.addform = data;
   this.mainframeType=this.addform.mainframeType
   this.equipmentList = data.equipmentList;
   this.stepActive = 1;
  },
  commitData2(data) {
   console.log(data, "-commitData2");
    this.addform.floorList = data;
   this.stepActive = 2;
   setTimeout(() => {
      this.$refs.data3.initForm(this.equipmentList);
      
   }, 10);
  },
  commitData3(data) {
   console.log(data, "-commitData3");
   this.addform.equipmentList=JSON.parse(JSON.stringify(data.equipmentList))
   this.stepActive = 3;
   setTimeout(() => {
      this.$refs.configView.getformData(this.addform)
   }, 10);
  },

  openSet() {
   this.showDialog = true;
      this.stepActive=0
      setTimeout(() => {
         this.ininAllForm()
      }, 10);
    
  },
  ininAllForm(type){
     this.$refs.brandForm.init()
      this.$refs.floorForm.init()
      if(type=='close'){
         this.showDialog=false
      }
  }
 },
};
</script>
<style lang="scss" scoped>

</style>


