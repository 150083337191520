import {
  MessageBox,
  Message
} from 'element-ui';
// 按键增加
export function addLoopMsg(ruleForm, r3000, key = 'equipmentButtonList') {
  if (
    ruleForm.eqType == r3000.temperatureControl &&
    ruleForm[key].length > 0
  ) {
    Message.warning("温控面板最多可添加一个回路");
    return false;
  }
  if (
    ruleForm.eqType.indexOf(r3000.dimming) != -1 &&
    ruleForm[key].length > 0
  ) {
    Message.warning("调光面板最多可添加一个回路");
    return false;
  }
  if (key == 'eqKeyList') {
    if (ruleForm[key].length >= 4) {
      Message.warning("场景面板最多可添加4个回路");
      return false;
    }
  } else if (ruleForm[key].length >= 6) {
    Message.warning("最多可添加6个回路");
    return false;
  }
  return true
}
// 按键删除
export function removeLoopInfo(index, ruleForm, r3000, key = 'equipmentButtonList') {
  console.log(index, ruleForm, r3000);
  if (
    ruleForm.eqType == r3000.clear &&
    ruleForm[key].length > 2 &&
    index < 2
  ) {
    ruleForm[key].splice(2, 1);
  } else {
    ruleForm[key].splice(index, 1);
  }
  return ruleForm[key]
}

// 判断是否为自定义场景
export function sceneCodeSelfShow(code, bySelfCode) {
  if (code) {
    if (code.indexOf(bySelfCode) != -1) return false;
  }
  return true;
}
// 提交参数 对按键的封装
export function getDataReqKey(dataForm, r_Name, r3000, and, bySelfCode) {
  console.log(dataForm, 'dataForm', r_Name, r3000, and, bySelfCode);
  let data = new Object()
  if (dataForm.mainframeType == r_Name) {
    // data.eqCode = dataForm.eqCode; //设备编码
    data.equipmentButtonList = [];
    dataForm.equipmentButtonList.forEach((v, index) => {
      let obj = {};
      obj.loopNumber = index + 1;
      if (
        dataForm.eqType == r3000.scene ||
        (dataForm.eqType == r3000.clear)
      ) {
        if (dataForm.eqType == r3000.clear && index >= 2) {
          obj.sceneCode = `${bySelfCode}${index-1}`
          obj.loopName = v.loopName;
        } else {
          let str = v.loopName.split(and);
          if (str.length > 1) {
            obj.sceneCode = str[0];
            obj.loopName = str[1];
          } else {
            obj.loopName = v.loopName;
          }
          // obj.panelType = 0;
        }
      } else {
        obj.loopName = v.loopName;
      }
      data.equipmentButtonList.push(obj);
    });
    if (dataForm.eqType == r3000.scene) {
      dataForm.eqKeyList.forEach((v, index) => {
        let obj = {};
        obj.loopNumber = index + 1;
        obj.loopName = v.loopName;
        //  obj.panelType = 1;
        data.equipmentButtonList.push(obj);
      });
    }
  }
  return data

}


export function initEqListFunction(mainframeType, r_Name, ruleForm, r3000, and) {
  if (
    mainframeType == r_Name &&
    (ruleForm.eqType == r3000.scene ||
      ruleForm.eqType == r3000.clear)
  ) {
    let equipmentButtonList = [],
      equipmentTgLightList = [],
      eqKeyList = [];

    ruleForm.equipmentButtonList.forEach((v, index) => {
      if (ruleForm.eqType == r3000.clear) {
        if (index < 2 && v.sceneCode) {
          v.loopName = `${v.sceneCode}${and}${v.loopName}`;
        }
        equipmentButtonList.push(v);
      } else if (ruleForm.eqType == r3000.scene) {
        if (v.sceneCode) {
          v.loopName = `${v.sceneCode}${and}${v.loopName}`;
          equipmentButtonList.push(v);
        } else {
          eqKeyList.push(v);
        }
      }
    });
    if (ruleForm.equipmentTgLightList) {
      ruleForm.equipmentTgLightList.forEach((v, index) => {
        equipmentButtonList.push(v);
      });
    }

    ruleForm.equipmentButtonList = JSON.parse(JSON.stringify(equipmentButtonList));
    ruleForm.equipmentButtonList = equipmentButtonList;
    ruleForm.eqKeyList = eqKeyList;
  }
  return ruleForm
}

//  已选场景禁用
export function disabledSceneKey(key, equipmentButtonList) {
  let show = false;
  if (equipmentButtonList) {
    try {
      equipmentButtonList.forEach((v) => {
        if (v.loopName == key) {
          show = true;
          throw show;
        }
      });
    } catch (error) {}
  }
  return show;
}