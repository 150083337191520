<template>
 <div class="my-box">
  <el-form :model="addform" ref="addform"  label-width="60px">
   <el-tabs type="border-card" v-model="activeName" @tab-click="changeActiveName">
    <el-tab-pane v-for="(item,idx) in addform.equipmentList" :key="item.value" :label="item.roomTypeName">
     <div v-if="!item.equipmentInfoList||item.equipmentInfoList.length==0" style="text-align: center;">
      - - 暂 无 设 备 - -
     </div>
     <el-tag class="pointer" :disable-transitions="false" v-for="(iiem2,i) in item.equipmentInfoList" @close="handleClose(i)" :key="i" @click="changeTag(i)" closable :type="tagIndex==i?'success':'info'">
      {{iiem2.eqName}}
     </el-tag>
    </el-tab-pane>
   </el-tabs>
  </el-form>

  <sbAdd @commitSbData="commitSbData" @commitData3="commitData3" @updateSbData="updateSbData" @topPage="topPage" ref="sbAdd" :activeName="activeName" :tagIndex="tagIndex" :mainframeType="mainframeType" :eqTypeSelectData="eqTypeSelectData"  :SceneTypes="SceneTypes" />
  <!-- </el-card> -->
 </div>
</template>
<script>
import sbAdd from "./sbAdd";
export default {
 props: ["equipmentList", "eqTypeSelectData", "SceneTypes","mainframeType"],
 components: {
  sbAdd,
 },
 data() {
  return {
   activeName: 0,
   tagIndex: 0,
 
   loading: false,
   and: "roomtypeAnd",
   addform: {
    equipmentList: null,
   },
  };
 },

 methods: {
  handleClose(i) {
   this.addform.equipmentList[this.activeName].equipmentInfoList.splice(i, 1);
   if (i == this.tagIndex) {
    this.tagIndex = 0;
   }
   this.setChildForm();
   this.$forceUpdate();
  },
  changeActiveName(val) {
   console.log(val);
   this.tagIndex = 0;
   this.setChildForm();
  },
  setChildForm() {
   if (
    this.addform.equipmentList[this.activeName].equipmentInfoList &&
    this.addform.equipmentList[this.activeName].equipmentInfoList.length
   ) {
    console.log(this.addform.equipmentList[this.activeName].equipmentInfoList);
    let data = JSON.parse(
     JSON.stringify(this.addform.equipmentList[this.activeName].equipmentInfoList[this.tagIndex])
    );
    this.initSbAdd(data);
   } else {
    this.initSbAdd();
   }
  },
  changeTag(i) {
   this.tagIndex = i;
   this.setChildForm();
  },
  initSbAdd(data){
    this.$refs.sbAdd.initForm(data);
  },
  initForm(data) {
    console.log(data);
   this.addform.equipmentList = data;
   this.$forceUpdate();
   this.initSbAdd()
  },
  getFormData(data, activeName) {
   this.activeName = activeName;
   this.addform = data;
   this.initSbAdd(data)
  },

  // 保存设备信息
  commitSbData(data) {
   console.log(data);
   console.log(this.addform.equipmentList,'-this.addform.equipmentList[this.activeName]');
   if (!this.addform.equipmentList[this.activeName].equipmentInfoList) {
    this.addform.equipmentList[this.activeName].equipmentInfoList = new Array();
   }
   this.addform.equipmentList[this.activeName].equipmentInfoList.push(data);
   this.initSbAdd();
   this.$forceUpdate();
  },
  updateSbData(data) {
   this.addform.equipmentList[this.activeName].equipmentInfoList[this.tagIndex] = data;
   this.initSbAdd();
   this.$forceUpdate();
  },
  // 下一步
  commitData3() {
    console.log(this.addform.equipmentList);
   let next = this.addform.equipmentList.some(item => !item.equipmentInfoList || item.equipmentInfoList.length == 0);
   if (next) {
    this.$message.error("请先添加客房模块");
    return;
   }
   this.$emit("commitData3", this.addform);
  },
  topPage() {
   this.$emit("topPage");
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 padding: 30px 10px 0;
}
.loopCountBar {
 width: 200px;
 display: flex;
 align-items: center;
 .kClass {
  width: 30px;
  // margin-right: 20px;
 }
 i {
  font-size: 20px;
  margin-left: 20px;
 }
}
.loopCountBar:nth-of-type(n + 2) {
 margin-top: 10px;
}
.el-tag + .el-tag {
 margin-left: 10px;
}
</style>


