<template>

 <div>
  <el-card style="margin-top:20px">
   <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="120px">
    <el-form-item label="设备类型" prop="eqType">
     <el-select v-model="ruleForm.eqType" @change="selectEqType" clearable placeholder="请选择">
      <el-option v-for="(item, index) in eqTypeSelectData" :key="index" :disabled="item.keyy=='TG_DENG'" :label="item.valuee+(item.keyy=='TG_DENG'?' ( 调光灯请到设备管理配置 ) ':'')" :value="item.keyy"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="设备名称" prop="eqName">
     <el-input v-model.trim="ruleForm.eqName" clearable placeholder="请输入设备名称"></el-input>
    </el-form-item>
    <el-form-item label="回路编号" prop="loopNumber" v-if="mainframeType != r_Name">
     <el-input v-model="ruleForm.loopNumber" clearable placeholder="请输入回路编号" type="number"></el-input>
    </el-form-item>
    <el-form-item label="按键名称" v-if="mainframeType == r_Name">
     <!-- <div v-for="(item, index) in ruleForm.equipmentButtonList" :key="index">
      <el-form-item :label="'按键' + (index + 1) + ':'" label-width="80px" :prop="'equipmentButtonList.' + index + '.loopName'" :rules="rules.loopName">
       <div class="loopCountBar">
        <el-select v-if="ruleForm.eqType == r3000.scene" v-model="item.loopName" clearable placeholder="请选择">
         <el-option v-for="(item, index) in SceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" v-if="sceneCodeSelf(item.keyy)" :label="item.valuee" :value="item.keyy + and + item.valuee"></el-option>
        </el-select>
        <el-select v-else-if="ruleForm.eqType==r3000.clear&&index<2" v-model="item.loopName" clearable placeholder="请选择">
         <el-option v-for="(item, index) in clearSceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" :label="item.valuee" :value="item.keyy + and + item.valuee"></el-option>
        </el-select>
        <el-input v-model="item.loopName" v-else clearable placeholder="请输入回路"></el-input>
        <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addLoop"></i>
        <i class="el-icon-remove-outline" v-if="index > 0" @click="removeLoop(index)"></i>
       </div>
      </el-form-item>
     </div> -->
     <div class="keyBar">
      <div class="keyBar1">
       <div v-for="(item, index) in ruleForm.equipmentButtonList" :key="index">
        <el-form-item :label="'按键' + (index + 1) + ':'" label-width="80px" :prop="'equipmentButtonList.' + index + '.loopName'" :rules="rules.loopName">
         <div class="loopCountBar">
          <el-select v-if="ruleForm.eqType == r3000.scene" v-model="item.loopName" clearable filterable placeholder="请选择">
           <el-option v-for="(item, index) in SceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" v-show="sceneCodeSelf(item.keyy)" :label="item.valuee" :value="item.keyy + and + item.valuee"></el-option>
          </el-select>
          <el-select v-else-if="ruleForm.eqType==r3000.clear&&index<2" v-model="item.loopName" clearable filterable placeholder="请选择">
           <el-option v-for="(item, index) in clearSceneTypes" :key="index" :disabled="disabledScene(item.keyy + and + item.valuee)" :label="item.valuee" :value="item.keyy + and + item.valuee"> </el-option>

          </el-select>

          <el-input v-model="item.loopName" v-else clearable placeholder="请输入回路"></el-input>

          <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addLoop"></i>
          <i class="el-icon-remove-outline" v-if="index > 0" @click="removeLoop(index)"></i>

         </div>
        </el-form-item>

       </div>

      </div>
      <div class="keyBar1" v-if="ruleForm.eqType == r3000.scene">

       <div v-for="(item, index) in ruleForm.eqKeyList" :key="index">
        <el-form-item :label="'回路按键' + (index + 1) + ':'" label-width="100px" :prop="'eqKeyList.' + index + '.loopName'" :rules="rules.loopName">
         <div class="loopCountBar">
          <el-input v-model="item.loopName" clearable placeholder="请输入回路按键"></el-input>
          <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addLoopKey"></i>
          <i class="el-icon-remove-outline" @click="removeLoopKey(index)"></i>
         </div>
        </el-form-item>
       </div>
       <div v-if="ruleForm.eqKeyList.length==0">

        <el-empty description="未设置回路按键" size="mini" :image-size="80">
         <el-button size="mini" type="primary" @click="addLoopKey">添加回路按键</el-button>
        </el-empty>
       </div>
      </div>
      <div>
        {{ruleForm.mainframeType != r_Name&&ruleForm.eqType==tgModel}}--
        {{ruleForm.mainframeType }}--
        {{ruleForm.eqType}}
      </div>
     
     </div>
    </el-form-item>
     <el-form-item label="调光灯" v-if="ruleForm.mainframeType != r_Name&&ruleForm.eqType==tgModel">

       <el-row v-for="(item, index) in ruleForm.equipmentTgLightList" :key="index">
        <el-col :span="7">
         <el-form-item label="调光灯类型" label-width="120px" :prop="'equipmentTgLightList.' + index + '.eqType'" :rules="rules.eqType">
          <!-- <div class="loopCountBar"> -->
          <el-select v-model="item.eqType" clearable filterable placeholder="请选择">
           <el-option v-for="(item, index) in eq_tg_list" :key="index" :label="item.valuee" :value="item.keyy"></el-option>
          </el-select>

         </el-form-item>
        </el-col>
        <el-col :span="7">
         <el-form-item label="设备名字" label-width="120px" :prop="'equipmentTgLightList.' + index + '.eqName'" :rules="rules.lightName">

          <el-input v-model="item.eqName" clearable placeholder="请调光名字"></el-input>

         </el-form-item>
        </el-col>
        <el-col :span="7">
         <el-form-item label="回路" label-width="120px" :prop="'equipmentTgLightList.' + index + '.loopNumber'" :rules="rules.tgloopNumber">
          <el-input-number :min="1" :max="18" v-model="item.loopNumber"></el-input-number>

         </el-form-item>
        </el-col>
        <el-col :span="3" class="reset-button">
         <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addTgLoop"></i>
         <i class="el-icon-remove-outline" v-if="index > 0" @click="removeTgLoop(index)"></i>
        </el-col>
       </el-row>

       <!-- </div>

      </div> -->
      </el-form-item>

    <el-form-item label="">
     <div class="dialog-footer ">
      <el-button type="primary" @click="confirmAdd('ruleForm')" :loading="loading">{{type=='update'?'修 改':'继续添加'}}</el-button>
     </div>
    </el-form-item>
   </el-form>
  </el-card>

  <div class="dialog-footer " style="margin-top:30px">
   <el-button @click="topPage">上一步</el-button>
   <el-button type="primary" @click="next">下一步</el-button>
  </div>
 </div>
</template>
<script>
import {
 addLoopMsg,
 removeLoopInfo,
 sceneCodeSelfShow,
 getDataReqKey,
 disabledSceneKey,
 initEqListFunction,
} from "@/api/eqInfoCommon.js";
export default {
 props: [
  "eqTypeSelectData",
  "SceneTypes",
  "tagIndex",
  "activeName",
  "mainframeType",
 ],
 data() {
  return {
   type: null,
   //  mainframeType:null,
   r_Name: "R3000",
   tgModel: "TG",
   TG_DENG: "TG_DENG",
   loading: false,
   and: "roomtypeAnd",
   demoForm: {
    eqName: null,
    eqType: null,
    equipmentButtonList: [{ loopName: null }],
    eqKeyList: [{ loopName: null }],
     equipmentTgLightList: [
     {
      eqType: null,
      loopNumber:1,
      eqName: null,
     },
    ],
   },
   ruleForm: {
    eqName: null,
    eqType: null,
    equipmentButtonList: [{ loopName: null }],
    equipmentTgLightList: [
     {
      eqType: null,
      loopNumber:1,
      eqName: null,
     },
    ],
    eqKeyList: [{ loopName: null }],
   },
   rules: {
    eqType: [
     { required: true, message: "请选择设备类型", trigger: ["blur", "change"] },
    ],
    lightName: [
     {
      required: true,
      message: "调光灯名字未设置",
      trigger: ["blur", "change"],
     },
    ],
    eqName: [
     { required: true, message: "请输入设备名称", trigger: ["blur", "change"] },
    ],
    eqKeyList: [
     { required: true, message: "请设置回路", trigger: "blur" },
     // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
    ],
    loopName: [
     { required: true, message: "按键未设置", trigger: ["blur", "change"] },
    ],
    loopNumber: [
     { required: true, message: "请输入回路编号", trigger: "blur" },
     // { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
    ],
   },
  };
 },

 computed: {
   eq_tg_list() {
   return this.eqTypeSelectData.filter((item)=>item.keyy.indexOf(this.TG_DENG) != -1);
  },
  r3000() {
   return this.$store.state.r3000;
  },
  hostType() {
   return this.$store.state.hostType;
  },
  bySelfCode() {
   return this.$store.state.bySelfCode;
  },
  clearSceneHas() {
   return this.$store.state.clearSceneHas;
  },
  clearSceneTypes() {
   return this.SceneTypes.filter((item) =>
    this.clearSceneHas.includes(item.keyy)
   );
  },

  list() {
   if (this.text) return arr.filter((item) => item.roomNumber == this.text);
   return arr;
  },
 },
 methods: {
    addTgLoop(){
    if(this.ruleForm.equipmentTgLightList&&this.ruleForm.equipmentTgLightList.length>=18){
      this.$message.error('调光模块最多拥有 18 个设备')
      return
    }
    this.ruleForm.equipmentTgLightList.push({
      eqType: null,
      loopNumber:this.ruleForm.equipmentTgLightList.length+1,
      eqName: null,
     });
   this.$forceUpdate();
  },
  removeTgLoop(index) {
    this.ruleForm.equipmentTgLightList.splice(index,1)
   this.$forceUpdate();
  },
  addLoopKey() {
   console.log(addLoopMsg(this.ruleForm, this.r3000, "eqKeyList"));
   if (!addLoopMsg(this.ruleForm, this.r3000, "eqKeyList")) return;
   this.ruleForm.eqKeyList.push({ loopName: undefined });
   this.$forceUpdate();
  },
  removeLoopKey(index) {
   let eqKeyList = removeLoopInfo(
    index,
    this.ruleForm,
    this.r3000,
    "eqKeyList"
   );
   this.$set(this.ruleForm, "eqKeyList", eqKeyList);
   this.$forceUpdate();
  },
  confirmAdd(formName, go) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     let data = this.getData(this.ruleForm);
     
     console.log(data,'-----------');
     setTimeout(() => {
      if (this.type == "update") {
       this.$emit("updateSbData", data);
      } else {
       this.$emit("commitSbData", data);
      }
      this.loading = false;
      this.ruleForm = JSON.parse(JSON.stringify(this.demoForm));
      setTimeout(() => {
       this.$refs[formName].clearValidate();
       if (go) {
        this.next();
       }
      }, 10);
     }, 500);
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  getData(dataForm) {
   let data = new Object();
   data.eqType = dataForm.eqType;
   data.eqName = dataForm.eqName;
   data.mainframeType = this.mainframeType;
   dataForm.mainframeType = this.mainframeType;
   if (this.mainframeType != this.r_Name) {
    data.loopNumber = dataForm.loopNumber;
   }
   if(data.eqType==this.tgModel){
    data.equipmentTgLightList=dataForm.equipmentTgLightList
  }
   let data2 = getDataReqKey(
    dataForm,
    this.r_Name,
    this.r3000,
    this.and,
    this.bySelfCode
   );
   console.log(data2, "---");
   data = Object.assign(data, data2);
   console.log(data);
   return data;
  
  },
  next() {
   if (this.ruleForm.eqType || this.ruleForm.eqName) {
    this.confirmAdd("ruleForm", "go");
    return;
   }

   this.$emit("commitData3");
  },
  topPage() {
   this.$emit("topPage");
  },
  initForm(data) {
   // this.mainframeType=type
   if (data) {
    this.ruleForm = data;
    this.ruleForm = initEqListFunction(
     this.mainframeType,
     this.r_Name,
     this.ruleForm,
     this.r3000,
     this.and
    );
    // this.$forceUpdate();
    this.type = "update";
   } else {
    this.type = "add";
    this.ruleForm = JSON.parse(JSON.stringify(this.demoForm));
    setTimeout(() => {
     this.$refs.ruleForm.clearValidate();
    }, 10);
   }
   this.$forceUpdate();
  },
  // selectEqType(val) {
  //  if (this.mainframeType == "R3000") {
  //   this.ruleForm.equipmentButtonList = [];
  //   this.ruleForm.equipmentButtonList.push({ loopName: undefined });
  //   if (this.ruleForm.eqType == this.r3000.scene) {
  //    this.ruleForm.eqKeyList = [];
  //    this.ruleForm.eqKeyList.push({ loopName: undefined });
  //   }
  //  } else {
  //   this.ruleForm.equipmentButtonList = undefined;
  //  }
  // },
  selectEqType(val) {
   console.log(val);
   if (
    val == this.tgModel &&
    (!this.ruleForm.equipmentTgLightList ||
     this.ruleForm.equipmentTgLightList.length == 0)
   ) {
    let arr = [];
    arr.push({
     eqType: null,
     loopNumber: 1,
     eqName: null,
    });
    this.ruleForm.equipmentTgLightList = arr;
   }
   console.log(this.ruleForm);
   this.initEQ();
  },
  initEQ() {
   if (this.ruleForm.mainframeType == this.r_Name) {
    this.ruleForm.equipmentButtonList = [];
    this.ruleForm.equipmentButtonList.push({ loopName: undefined });
    if (this.ruleForm.eqType == this.r3000.scene) {
     this.ruleForm.eqKeyList = [];
     this.ruleForm.eqKeyList.push({ loopName: undefined });
    }
    console.log(this.ruleForm, "-----------------this.ruleForm222");
   }
  },
  addLoop() {
   if (!this.ruleForm.eqType) {
    this.$message.error("请选择设备类型");
    return;
   }
   if (!addLoopMsg(this.ruleForm, this.r3000)) return;
   this.ruleForm.equipmentButtonList.push({ loopName: undefined });
   this.$set(
    this.ruleForm,
    "equipmentButtonList",
    this.ruleForm.equipmentButtonList
   );
  },
  removeLoop(index) {
   let equipmentButtonList = removeLoopInfo(index, this.ruleForm, this.r3000);
   this.$set(this.ruleForm, "equipmentButtonList", equipmentButtonList);
  },
  sceneCodeSelf(code) {
   return sceneCodeSelfShow(code, this.bySelfCode);
  },
  disabledScene(key) {
   return disabledSceneKey(key, this.ruleForm.equipmentButtonList);
  },
 },
};
</script>
<style lang="scss" scoped>
.keyBar {
 display: flex;
 // align-items: flex-start;
}
.keyBar1 {
 width: 50%;
 //  display: inline-block;
}
.pointer {
 cursor: pointer;
}
.my-box {
 padding: 30px 10px 0;
}
.loopCountBar {
 width: 200px;
 display: flex;
 align-items: center;
 .kClass {
  width: 30px;
  // margin-right: 20px;
 }
 
}
.loopCountBar:nth-of-type(n + 2) {
 margin-top: 10px;
}
i {
  font-size: 24px;
  margin-left: 20px;
  cursor: pointer;
 }
</style>


